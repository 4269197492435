// Modules
import slugify from "slugify";

/**
 * The debounce function limits the amount of times a function is called when triggered.
 * See https://medium.com/@TCAS3/debounce-deep-dive-javascript-es6-e6f8d983b7a1.
 * @param callback
 * @param time
 * @returns {function(...[*]=)}
 */
export const debounce = (callback, time) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      callback.apply(context, args);
    }, time);
  };
};

/**
 * Takes in Strapi date string and returns 'MMMM D, YYYY' formatted date.
 * @param date
 * @returns {string}
 */
export const formatDate = date => {
  const dateArr = date.split("-");

  if (dateArr.length !== 3) {
    return date;
  }

  const months = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    10: "October",
    11: "November",
    12: "December"
  };

  const month = months[dateArr[1]];
  const day = parseInt(dateArr[2]);
  const year = parseInt(dateArr[0]);

  if (month && day && year) {
    return `${month} ${day}, ${year}`;
  } else {
    return date;
  }
};

/**
 * Converts string to kebab case.
 * @param string
 * @returns {string}
 */
export const convertToKebabCase = string => slugify(string).toLowerCase();
