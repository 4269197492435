// Modules
import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";

// Utils
import { columnSize } from "../utils/responsive";
import { formatDate, convertToKebabCase } from "../utils/helpers";

// Components
import SEO from "../components/SEO";
import Button from "../components/Button";
import Layout from "../components/Layout";
import Section, { SectionMarkdown } from "../components/Section";
import HeroSection, { HeroImageArticle } from "../components/HeroSection";
import { H1, H2, H3 } from "../components/TextHeadings";
import Paragraph from "../components/Paragraph";
import Analytics from "../utils/tracking";

const ArticleTemplate = ({ data }) => {
  const strapiArticle = data.strapiArticle;

  useEffect(() => {
      async function trackArticle() {
        await Analytics.track("Article loaded", { title: strapiArticle.title })
      }
      trackArticle()
  },[])

  return (
    <Layout>
      <SEO
        title={strapiArticle.title}
        description={strapiArticle.content}
        isArticle={true}
        url={`/blog/${strapiArticle.strapiId}/${convertToKebabCase(
          strapiArticle.title
        )}`}
        image={strapiArticle.image?.publicURL}
      />

      <HeroSection isDarkBackground={false}>
        <H2>{strapiArticle.category.name}</H2>
        <H1>{strapiArticle.title}</H1>
        <H3>Shared by {strapiArticle.author.fullName}</H3>
        <Paragraph>{formatDate(strapiArticle.published_at)}</Paragraph>
        {strapiArticle.image?.publicURL && (
          <HeroImageArticle
            src={strapiArticle.image.publicURL}
            alt={strapiArticle.title}
            maxWidth={columnSize.large}
          />
        )}
      </HeroSection>

      <Section isTextCentered={true}>
        <SectionMarkdown
          maxWidth={columnSize.medium}
          source={strapiArticle.content}
        />
      </Section>

      <Section isTextCentered={true} style={{paddingBottom: 124}}>
        <Link to="/blog">
          <Button type="secondary">Back to all articles</Button>
        </Link>
      </Section>
    </Layout>
  );
};

export default ArticleTemplate;

export const articleQuery = graphql`
  query ArticleTemplate($id: Int!) {
    strapiArticle(strapiId: { eq: $id }) {
      title
      content
      strapiId
      created_at
      updated_at
      published_at
      category {
        name
      }
      image {
        publicURL
      }
      author {
        fullName
      }
    }
  }
`;
