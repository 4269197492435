import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import colors from "../utils/colors";

const ButtonPrimary = css`
  background: ${colors.cobalt};
  border: none;
  color: white;
`;

const ButtonSecondary = css`
  background: none;
  border: 1px solid ${colors.cobalt};
  color: ${colors.cobalt};
`;

const ButtonLarge = css`
  font-size: 16px;
  padding: 7px 13px;
`;

const ButtonSmall = css`
  font-size: 14px;
  padding: 5px 10px;
`;

const Button = styled.button`
  ${props => (props.size === "small" ? ButtonSmall : ButtonLarge)}
  ${props => (props.type === "secondary" ? ButtonSecondary : ButtonPrimary)}
  border-radius: 5px;
  box-shadow: 0 0 0 0 ${colors.cobalt};
  cursor: pointer;
  font-family: Avenir, "Nunito Sans", helvetica, sans-serif;
  transition: box-shadow 100ms;

  &:focus {
    outline: 0;
  }
  &:active {
    box-shadow: 0 0 0 3px ${colors.caribbean};
  }
`;

Button.propTypes = {
  size: PropTypes.oneOf(["small", "large"]),
  type: PropTypes.oneOf(["primary", "secondary"])
};

export default Button;
